simulador = function () {
    var price = document.getElementById("price").value
    var tipoPay = document.getElementById("tipoPay").value

    var debito_out = document.getElementById("debito_out").value
    var debito = document.getElementById("debito").value
    var pix = document.getElementById("pix").value

    var MeioPay = document.getElementById("MeioPay").value

    if(MeioPay == 1)
    {
        htmlParcel = "<div class='row'><div class='col-12'><h4>Visa/ Master</h4></div>";
        for (let index = 1; index <= 18; index++) {
            const element = document.getElementById("parcel" + index).value

            price = price.toString().replace(',', '.');

            if (tipoPay == 1) {
                price_cc = price / (1 - (element / 100))
            } else if (tipoPay == 2) {
                price_cc = price * ((100 - element) / 100)
            }

            htmlParcel += "<div class='col-3'><p>" + index + "x - R$" + price_cc.toFixed(2) + "</p></div>"

        }
        htmlParcel += "</div>"
        htmlParcel += "<div class='row'><div class='col-12'><h4>Elo/ Amex/ Hipercard</h4></div>";

        for (let index = 1; index <= 18; index++) {
            const element = document.getElementById("parcelTwo" + index).value

            price = price.toString().replace(',', '.');

            if (tipoPay == 1) {
                price_cc = price / (1 - (element / 100))
            } else if (tipoPay == 2) {
                price_cc = price * ((100 - element) / 100)
            }

            htmlParcel += "<div class='col-3'><p>" + index + "x - R$" + price_cc.toFixed(2) + "</p></div>"

        }
        htmlParcel += "</div>"


    }else{
        price = price.toString().replace(',', '.');

        if (tipoPay == 1) {
            debito_out = price / (1 - (debito_out / 100))
            debito = price / (1 - (debito / 100))
            pix = price / (1 - (pix / 100))
        } else if (tipoPay == 2) {
            debito = price * ((100 - debito) / 100)
            debito_out = price * ((100 - debito_out) / 100)
            pix = price * ((100 - pix) / 100)
        }

        htmlParcel = "<div class='row'><div class='col-12'><h4>Debito Visa/ Maste</h4>R$ " + debito.toFixed(2) + "<h4>Debito Elo/ Amex/ Hipercard</h4> R$ " + debito_out.toFixed(2) + "<h4>PIX</h4> R$ " + pix.toFixed(2) + "</div>";
        htmlParcel += "</div>"
    }

    document.getElementById("htmlTaxas").innerHTML = htmlParcel
}
